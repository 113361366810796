import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/commonStyle.css';
import './assets/css/commonLayoutStyle.scss';
import '@/router/guard'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import go from "gojs";
import i18n from './i18n'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store).use(i18n).use(ElementPlus).use(router).mount('#app')

go.Diagram.licenseKey = `${process.env.VUE_APP_GO_JS_API_KEY}`;
window.addEventListener('storage', function () {
  sessionStorage.clear();
  window.location.replace("/");
});