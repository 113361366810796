const messages_en = {
  COM_C_0004: "Do you want to discard the new creation of {0} and return to {1}?",
  COM_C_0005: "Do you want to delete {0}?",
  COM_C_0006: "Do you want to discard the update of {0} and return to {1}?",
  COM_C_0007: "Do you want to register {0}?",
  COM_C_0009: "Do you want to update {0}?",
  COM_C_0029: "File download completed.",
  COM_E_0001: "Please enter half-width alphanumeric symbols for {0}.",
  COM_E_0002: "Please enter {0} for {1} characters or less.",
  COM_E_0003: "No data matching the conditions found.",
  COM_E_0010: "{0} not found.",
  COM_E_0012: "{0} is a required field.",
  COM_E_0013: "The character type of {0} is {1}.",
  COM_E_0019: "The integer part of {0} exceeds the maximum digits {1}, or the decimal part exceeds the maximum digits {2}.",
  COM_E_0022: "Item {1} on line {0} is a required field.",
  COM_E_0026: "Please specify a correct value for item \"{0}\".",
  COM_E_0040: "Select at least {1} {0}.",
  COM_E_0045: "Please enter half-width numeric symbols for {0}.",
  COM_E_0046: "Please enter half-width alphanumeric characters for {0}.",
  COM_E_0049: "Please enter half-width numeric characters for {0}.",
  COM_E_0050: "File download failed.",
  COM_E_0051: "The number of items for search condition \"{0}\" exceeds the maximum {1}.",
  COM_E_0059: "The start date of the effective period is set after the expiration date.",
  COM_E_0061: "Please enter alphanumeric and special characters for {0}.",
  COM_E_0064: "Please enter a valid email address.",
  COM_E_0070: "Please enter at least {1} characters for {0}.",
  COM_E_0071: "Please enter alphanumeric for {0}.",
  COM_E_0072: "The number of items for entry condition \"{0}\" exceeds the maximum {1}.",
  COM_E_0073: "The expiration date has passed. Unable to perform new creation, copy, or deletion.",
  COM_E_0077: "System timeout occurred. Please contact the system administrator.",
  COM_I_0085: "Upload has been completed.",
  COM_E_0094: "The input format for {0} is \"Last Name + space + First Name\".",
  COM_E_0995: "Due to inactivity, you will be automatically logged out within {0} minutes.",
  COM_E_0996: "Inactivity timeout for {0} minutes.",
  COM_E_0997: "You will be automatically logged out within {0} minutes. Please log in again immediately.",
  COM_E_0998: "A network error occurred. Please contact the system administrator.",
  COM_E_0999: "A system error occurred.",
  COM_E_0120: "Request authorization error occurred. Please contact the system administrator.",
  COM_E_0121: "The request was invalid. Please contact the system administrator.",
  COM_E_1006: "Please upload a file in CSV format.",
  COM_E_0124: "Do you want to download error message?",
  COM_E_0125: "Do you want to log out?",
  COM_E_0126: "The error list download file has been completed.",
  COM_I_0127: "An email has been sent. If you do not receive the email, please confirm your User ID.",
  COM_E_0128: "The password has been changed.",
  COM_I_2001: "The {0} is processing. Please check the status via online batch menu.",
  COM_E_2020: "At least one of the following items \"{0}\" is required.",
  PF01C01D01_E_0003: "*The new password and confirming password does not match.",
  PF01C01D01_E_0004: "{0} must be at least 12 characters.",
  PF01C01D01_E_0005: "Please enter a password that contains at least one uppercase letter, lowercase letter, number and a special character.",
  PF01C01D01_E_0007: "You cannot set a password that is the same as your User ID.",
  PF01C01D01_W_0001: "The password for this user will expire in {0} days.",
  PF01C01D01_E_0010: "Your password has expired.",
  PF01C01D01_I_0002: "Please change the initial password.",
  PF01C01D01_E_0011: "Please enter a valid email address.",
  PF01F09D03_E_0002: "設定されている母材の割合の合計が「100%」でなければなりません。",
  PF01F09D06_C_0001: "更新を破棄して、検索結果画面へ戻りますか？",
  PF01F10D05_C_001: "紐付けする鋼材情報が異なります。更新しますか？",
  PF01F13D02_C_001: "選択した原単位連関表を承認しますか?",
  PF01F13D02_E_001: "承認対象の品目を選択してください。",
  PF01F13D02_I_001: "原単位連関表を承認しました。",
  PF01M01D03_E_0002: "Failed to retrieve the initial value of the company type. Please contact the system administrator.",
  PF01M01D05_C_0002: "\"Company name (Abbrev.): {0}\" already exists. Do you want to continue with the registration?",
  PF01M02D03_C_0002: "\"Location name: {0}\" already exists. Do you want to continue with the registration?",
  PF01M03D03_C_0002: "\"User name: {0}\" already exists. Do you want to continue with the registration?",
  PF01M03D05_C_0002: "\"User name: {0}\" already exists. Do you want to continue with the update?",
  PF02F09D02_C_0001: "母材月次発注量を承認しますか？",
  PF01F10D05_E_0012: "更新されている項目がありません。",
  PF01F10D05_E_0015: "母材グループと母材、または母材グループを2つ紐づけることができません。",
  PF02F01D02_I_0002: "納入日程表のアップロードが成功しました。",
  PF02F02D02_I_0002: "車両生産台数のアップロードが成功しました。",
  PF02F03D02_I_0002: "納入日程表（補用品）のアップロードが成功しました。",
  PF02F01D02_C_0001: "同旬の納入日程表はアップロード済みですが、上書きしてアップロードを続けますか？<br>　確認内容（前回、登録済みファイル情報）",
  PF02F02D02_C_0001: "同旬の車両生産台数はアップロード済みですが、上書きしてアップロードを続けますか？<br>　確認内容（前回、登録済みファイル情報）",
  PF02F03D02_C_0001: "同旬の納入日程表（補用品）はアップロード済みですが、上書きしてアップロードを続けますか？<br>　確認内容（前回、登録済みファイル情報）",
  PF02F05D02_C_0002: "車両台数を変更し、再計算実行します。<br>車両台数変更の反映範囲を選択してください。<br>　全体：現在の検索結果に表示されている同車種に反映<br>　調整項目：調整した箇所のみに反映",
  PF02F05D02_C_0003: "所要量計算結果の調整情報を保存しますか？",
  PF02F05D02_C_0004: "所要量計算結果の承認情報を保存しますか？",
  PF02F05D02_I_0002: "所要量計算結果の調整情報を保存しました。",
  PF02F05D02_I_0003: "所要量計算結果の承認情報を保存しました。",
  PF02F05D02_I_0005: "調整後のデータがないため、再計算処理を行いません。",
  PF02F09D02_I_0002: "母材月次発注量を承認しました。",
  PF02F06D01_I_0001: "未承認データがありません。",
  PF01F07D02_I_0001: "More than one item classification exists for download. Select the item classification that you want to download.",
  PF02F05D02_E_0003: "同じ母材単位の鋼材・母材を全部チェックしてください。",
  PF02F05D02_E_0005: "調整値、調整メモのいずれかが更新されましたが、<br>反映されていません。<br>再計算、保存を実行してから承認してください。",
  PF02F05D02_E_0006: "調整値が更新されましたが、反映されていません。<br>再計算を実行してから保存してください。",
  PF02F05D02_E_0007: "保存対象のデータがないため、保存処理を行いません。<br>承認メモは、承認ボタンで保存してください。",
  PF02F05D02_E_0008: "調整値、調整メモのいずれかが更新されましたが、未保存です。<br>保存を実行してから承認してください。",
  PF02F05D02_E_0009: "調整メモが更新されましたが、未保存です。<br>保存を実行してから承認してください。",
  PF02F05D02_C_0005: "調整値、調整メモ、承認メモのいずれかが更新されましたが、反映されていません。<br>変更を破棄して次の画面に移りますか？",
  PF02F05D02_C_0006: "承認メモの変更が破棄され、<br>再度入力する必要がありますが、処理を進めますか？",
  PF02F05D02_C_0007: "調整メモ、承認メモのいずれかが更新されましたが、未保存です。<br>変更を破棄して次の画面に移りますか？",
  PF02F05D02_C_0008: "承認メモが更新されましたが、未保存です。<br>変更を破棄して次の画面に移りますか？",
  PF02F05D02_C_0009: "調整値、調整メモ、承認メモのいずれかが更新されましたが、未保存です。<br>変更を破棄して次の画面に移りますか？",
  PF03F03D02_E_0001: "No data found for this condition",
  PF03F05D01_C_0001: "If Specifying Multiple Conditions, Please Enter Them Separated By '",
  PF03F05D01_C_0002: "|'.",
  PF03F05D04_C_0001: "Do you want to save the comment?",
  PF03F05D04_C_0002: "Please confirm the order change rejection. Do you want to make changes again?",
  PF03F05D04_C_0005: "Do you want to save the order change?",
  PF03F05D04_C_0006: "Do you want to approve the order?",
  PF03F05D04_C_0007: "Do you want to reject the order?",
  PF03F05D04_C_0008: "Do you want to confirm the order rejection?",
  PF03F05D04_C_0009: "Do you want to save the shipment information?",
  PF03F05D04_C_0010: "Do you want to save the current month's billing classification information?",
  PF03F05D04_C_0011: "Do you want to close the detail screen?",
  PF03F05D04_C_0012: "There is no data to save.",
  PF03F05D04_C_0013: "Please save the content of update except for the comment.",
  PF03F05D04_C_0014: "Please save the content of update.",
  PF03F05D04_C_0015: "Please save the content of update except for the order change area.",
  PF03F05D04_C_0016: "The shipment information has been updated, but has not been reflected.<br>Please save in the adjustment area.",
  PF03F05D04_C_0017: "The current month's billing classification information has been updated, but has not been reflected.<br>Please save in the adjustment area.",
  PF03F05D04_C_0018: "Please save the content of update before switch the indicator.",
  PF03F05D04_E_0001: "There is no data to save.",
  PF03F05D04_E_0002: "Please save the content of update except for the comment.",
  PF03F05D04_E_0003: "Please save the content of update except for the order change area.",
  PF03F05D04_E_0005: "The shipment information has been updated, but has not been reflected. Please save in the adjustment area.",
  PF03F05D04_E_0006: "Please save the content of update.",
  PF03F05D04_E_0007: "Unable to update because another user has updated the info. Please refresh the screen and update again.",
  PF03F05D04_E_0013: "Please save the content of update before switch the indicator.",
  PF03F05D04_I_0001: "The comment has been saved.",
  PF03F05D04_I_0002: "The change rejection has been confirmed.",
  PF03F05D04_I_0005: "The order change has been saved.",
  PF03F05D04_I_0006: "The order has been approved.",
  PF03F05D04_I_0007: "The order has been rejected.",
  PF03F05D04_I_0008: "The order rejection has been confirmed.",
  PF03F05D04_I_0009: "The shipment information has been saved.",
  PF03F05D04_I_0010: "The current month's billing classification information has been saved.",
  PF03F14D01_E_0001: "Unable to retrieve the sales order company list.",
  PF03F14D01_E_0002: "Unable to retrieve the list of steel processing companies.",
  PF03F14D01_E_0003: "Unable to retrieve the sales order company list and steel processing companies list.",
  PF03F14D01_E_0004: "Please enter your company code in the sales order company code or the steel processing company code.",
  PF03F03XXX_I_0001: "An approval request for an order has been received.",
  PF03F03XXX_I_0002: "A reject confirmation request for an order has been received.",
  PF03F03XXX_I_0003: "An error occurred in the upload.",
  PF03F03XXX_I_0004: "A comment have been added."
}

export default messages_en;