import store from "@/store";

/**
 * システム言語に相関の静的な配置
 */
export const Lang = {
  JA: {
    name: 'ja',
    sjis: 'SJIS',
    timeZone: 'Asia/Tokyo'
  },
  EN: {
    name: 'en',
    utf8: 'UTF8',
    unicode: 'UNICODE',
    timeZone: 'Asia/Jakarta'
  },
};

/**
 * ブラウザ言語を取得する
 * 
 * @returns ブラウザ言語
 */
export const getBrowserLang = (): string => {
  const naviLang = navigator.language;
  if (naviLang === 'en' || naviLang === 'en-US' || naviLang === 'en-us') {
    return Lang.EN.name; 
  }
  return Lang.JA.name; 
}

/**
 * 選択される言語を設定する
 * 
 * @param lang システム言語
 */
export const setLang = (lang: any): void => {
  // storeに格納
  store.commit('setLang', lang);
  // sessionStorageに保存
  sessionStorage.setItem('x-lang', lang);
};  

/**
 * メモリのsessionStorageから、システム言語を直接に取得する.
 * 
 * @returns システム言語の文字列
 */
export const getLang = (): any => {
  // sessionStorageから取得
  const lang = sessionStorage.getItem('x-lang');
  if(lang){
    return lang;
  }
  setLang(getBrowserLang());
  return getBrowserLang();
};

/**
 * 日本語環境かを判断する.
 * 
 * @returns 日本語環境か
 */
export const isJA = (): boolean => {
  return getLang() === Lang.JA.name ? true : false;
};

/**
 * 英語環境かを判断する.
 * 
 * @returns 英語環境か
 */
export const isEN = (): boolean => {
  return getLang() === Lang.EN.name ? true : false;
};
