const actions_ja = {
  PF_ACTION_00001: "ログイン",
  PF_ACTION_00002: "ログアウト",
  PF_ACTION_00003: "詳細検索",
  PF_ACTION_00004: "ダウンロード",
  PF_ACTION_00005: "検索",
  PF_ACTION_00006: "閉じる",
  PF_ACTION_00007: "新規作成",
  PF_ACTION_00008: "ページに移動",
  PF_ACTION_00009: "戻る",
  PF_ACTION_00010: "編集",
  PF_ACTION_00011: "削除",
  PF_ACTION_00012: "更新",
  PF_ACTION_00013: "確定",
  PF_ACTION_00014: "アップロード",
  PF_ACTION_00015: "参照",
  PF_ACTION_00016: "追加",
  PF_ACTION_00017: "承認",
  PF_ACTION_00018: "承認対象リスト表示",
  PF_ACTION_00019: "パスワード変更",
  PF_ACTION_00020: "トップページ",
  PF_ACTION_00021: "原単位連関表",
  PF_ACTION_00022: "原単位連関表_一括照会",
  PF_ACTION_00023: "原単位連関表 照会",
  PF_ACTION_00024: "原単位連関表_未確定 照会",
  PF_ACTION_00025: "部品表_最上位 登録",
  PF_ACTION_00026: "部品表_構成部品 登録",
  PF_ACTION_00027: "母材・鋼材_未登録 照会",
  PF_ACTION_00028: "母材・鋼材_一括登録",
  PF_ACTION_00029: "品目登録・照会",
  PF_ACTION_00030: "母材グループ登録・照会",
  PF_ACTION_00031: "連関情報登録・照会",
  PF_ACTION_00032: "原単位連関表 承認",
  PF_ACTION_00033: "承認メニュー一覧",
  PF_ACTION_00034: "保守運用",
  PF_ACTION_00035: "企業マスタ",
  PF_ACTION_00036: "ロケーションマスタ",
  PF_ACTION_00037: "ユーザーマスタ",
  PF_ACTION_00038: "エラー一覧ダウンロード",
  PF_ACTION_00039: "キャンセル",
  PF_ACTION_00040: "送信",
  PF_ACTION_00041: "日次DX",
  PF_ACTION_00042: "日次鋼材注文 登録・変更",
  PF_ACTION_00043: "通知一覧",
  PF_ACTION_00044: "共通納期管理表",
  PF_ACTION_00045: "出荷実績(出荷予定)登録・変更",
  PF_ACTION_00046: "アップロードバッチ照会",
  PF_ACTION_00047: "ダウンロードバッチ照会",
  PF_ACTION_00048: "グループマスタ",
  PF_ACTION_00049: "ユーザ・グループマスタ",
  PF_ACTION_00050: "リードタイムマスタ",
  PF_ACTION_00051: "納入先マスタ",
  PF_ACTION_00052: "共通納期管理表ダウンロード",
  PF_ACTION_00053: "承認対象確認",
  PF_ACTION_00054: "OK",
  PF_ACTION_00055: "コピー",
  PF_ACTION_00056: "登録",
  PF_ACTION_00057: "ダウンロード検索",
  PF_ACTION_00058: "棄却確認",
  PF_ACTION_00059: "承認対象確認解除",
  PF_ACTION_00060: "",
}
  
export default actions_ja;