const messages_ja = {
  COM_C_0004: "{0}の新規作成を廃棄して、{1}へ戻りますか？",
  COM_C_0005: "{0}を削除しますか？",
  COM_C_0006: "{0}の更新を廃棄して、{1}へ戻りますか？",
  COM_C_0007: "{0}を登録しますか？",
  COM_C_0009: "{0}を更新しますか？",
  COM_C_0029: "ファイルダウンロード完了。",
  COM_E_0001: "{0}は半角英数記号を入力してください。",
  COM_E_0002: "{0}は{1}文字以内で入力してください。",
  COM_E_0003: "該当条件のデータが見つかりません。",
  COM_E_0010: "{0}が見つかりません。",
  COM_E_0012: "{0}は必須項目です。",
  COM_E_0013: "{0}の文字種は{1}です。",
  COM_E_0019: "{0}の整数部の桁数が最大桁数{1}、または小数部の桁数が最大桁数{2}を超えています。",
  COM_E_0022: "{0}行目の項目「{1}」は必須項目です。",
  COM_E_0026: "項目{0}に正しい値を指定してください。",
  COM_E_0040: "{0}を最低{1}個選択してください。",
  COM_E_0045: "{0}は半角数字記号を入力してください。",
  COM_E_0046: "{0}は半角英数字を入力してください。",
  COM_E_0049: "{0}は半角数字を入力してください。",
  COM_E_0050: "ファイルのダウンロードに失敗しました。",
  COM_E_0051: "検索条件「{0}」の個数が最大値{1}を超えています。",
  COM_E_0059: "有効期間終了日より後の有効期間開始日が設定されています。",
  COM_E_0061: "{0}は半角カタカナ英数記号を入力してください。",
  COM_E_0064: "メールアドレスを正しく入力してください。",
  COM_E_0070: "{0}は{1}文字以上で入力してください。",
  COM_E_0071: "{0}は半角英字を入力してください。",
  COM_E_0072: "登録条件の項目「{0}」の個数が最大値{1}を超えています。",
  COM_E_0073: "有効期間終了日が過ぎています。新規作成、コピー又は削除処理を実行できません。",
  COM_E_0077: "システムでタイムアウトが発生しました。システム管理者へご連絡ください。",
  COM_I_0085: "アップロードを完了しました。",
  COM_E_0094: "{0}の入力形式は「姓 + 半角スペース/全角スペース + 名」で指定して下さい。",
  COM_E_0995: "無操作のため、{0}分以内に、自動的にログアウトされます。",
  COM_E_0996: "{0}分間操作がなかったので、タイムアウトしました。",
  COM_E_0997: "{0}分以内に、自動的にログアウトされます。ただちに再ログインを実施してください。",
  COM_E_0998: "ネットワークエラーが発生しました。システム管理者へご連絡ください。",
  COM_E_0999: "システムエラーが発生しました。",
  COM_E_0120: "リクエスト認可エラーが発生しました、システム管理者へご連絡ください。",
  COM_E_0121: "リクエストが不正でした、システム管理者へご連絡ください。",
  COM_E_1006: "csv 形式のファイルをアップロードしてください。",
  COM_E_0124: "エラーメッセージをダウンロードしますか？",
  COM_E_0125: "ログアウトしますか？",
  COM_E_0126: "エラー一覧ダウンロードファイルがダウンロード完了しました。",
  COM_I_0127: "メールを送信しました。メールが届かない場合はユーザIDを再確認して下さい。",
  COM_E_0128: "パスワードを変更しました。",
  COM_I_2001: "{0}中です。{0}状況は{0}バッチ照会画面をご確認ください。",
  COM_E_2020: "「{0}」のいずれかが必須項目です。",
  PF01C01D01_E_0003: "*新パスワードと新パスワード(確認用)が一致しません。",
  PF01C01D01_E_0004: "{0}は12文字以上で入力してください。",
  PF01C01D01_E_0005: "英大文字・英小文字・数字・記号から最低一種類ずつ文字を入力してください。",
  PF01C01D01_E_0007: "ユーザIDと同一のパスワードは設定できません。",
  PF01C01D01_W_0001: "このユーザのパスワードはあと{0}日で期限切れになります。",
  PF01C01D01_E_0010: "パスワードの有効期限が切れました。",
  PF01C01D01_I_0002: "初期パスワードの変更を実施してください。",
  PF01C01D01_E_0011: "メールアドレスを正しく入力してください。",
  PF01F09D03_E_0002: "設定されている母材の割合の合計が「100%」でなければなりません。",
  PF01F09D06_C_0001: "更新を破棄して、検索結果画面へ戻りますか？",
  PF01F10D05_C_001: "紐付けする鋼材情報が異なります。更新しますか？",
  PF01F13D02_C_001: "選択した原単位連関表を承認しますか?",
  PF01F13D02_E_001: "承認対象の品目を選択してください。",
  PF01F13D02_I_001: "原単位連関表を承認しました。",
  PF01M01D03_E_0002: "企業種別の初期値取得に失敗しました。システム管理者へご連絡ください。",
  PF01M01D05_C_0002: "「企業名(表示用):{0}」は既に存在します。続けて登録しますか？",
  PF01M02D03_C_0002: "「ロケーション名:{0}」のロケーションは既に存在します。続けて登録しますか？",
  PF01M03D03_C_0002: "「ユーザ名:{0}」のユーザは既に存在します。続けて登録しますか？",
  PF01M03D05_C_0002: "「ユーザ名:{0}」のユーザは既に存在します。続けて更新しますか？",
  PF02F09D02_C_0001: "母材月次発注量を承認しますか？",
  PF01F10D05_E_0012: "更新されている項目がありません。",
  PF01F10D05_E_0015: "母材グループと母材、または母材グループを2つ紐づけることができません。",
  PF02F01D02_I_0002: "納入日程表のアップロードが成功しました。",
  PF02F02D02_I_0002: "車両生産台数のアップロードが成功しました。",
  PF02F03D02_I_0002: "納入日程表（補用品）のアップロードが成功しました。",
  PF02F01D02_C_0001: "同旬の納入日程表はアップロード済みですが、上書きしてアップロードを続けますか？<br>　確認内容（前回、登録済みファイル情報）",
  PF02F02D02_C_0001: "同旬の車両生産台数はアップロード済みですが、上書きしてアップロードを続けますか？<br>　確認内容（前回、登録済みファイル情報）",
  PF02F03D02_C_0001: "同旬の納入日程表（補用品）はアップロード済みですが、上書きしてアップロードを続けますか？<br>　確認内容（前回、登録済みファイル情報）",
  PF02F05D02_C_0002: "車両台数を変更し、再計算実行します。<br>車両台数変更の反映範囲を選択してください。<br>　全体：現在の検索結果に表示されている同車種に反映<br>　調整項目：調整した箇所のみに反映",
  PF02F05D02_C_0003: "所要量計算結果の調整情報を保存しますか？",
  PF02F05D02_C_0004: "所要量計算結果の承認情報を保存しますか？",
  PF02F05D02_I_0002: "所要量計算結果の調整情報を保存しました。",
  PF02F05D02_I_0003: "所要量計算結果の承認情報を保存しました。",
  PF02F05D02_I_0005: "調整後のデータがないため、再計算処理を行いません。",
  PF02F09D02_I_0002: "母材月次発注量を承認しました。",
  PF02F06D01_I_0001: "未承認データがありません。",
  PF01F07D02_I_0001: "ダウンロード対象に複数の品目種別が存在しています。ダウンロードする品目種別を選んでください。",
  PF02F05D02_E_0003: "同じ母材単位の鋼材・母材を全部チェックしてください。",
  PF02F05D02_E_0005: "調整値、調整メモのいずれかが更新されましたが、<br>反映されていません。<br>再計算、保存を実行してから承認してください。",
  PF02F05D02_E_0006: "調整値が更新されましたが、反映されていません。<br>再計算を実行してから保存してください。",
  PF02F05D02_E_0007: "保存対象のデータがないため、保存処理を行いません。<br>承認メモは、承認ボタンで保存してください。",
  PF02F05D02_E_0008: "調整値、調整メモのいずれかが更新されましたが、未保存です。<br>保存を実行してから承認してください。",
  PF02F05D02_E_0009: "調整メモが更新されましたが、未保存です。<br>保存を実行してから承認してください。",
  PF02F05D02_C_0005: "調整値、調整メモ、承認メモのいずれかが更新されましたが、反映されていません。<br>変更を破棄して次の画面に移りますか？",
  PF02F05D02_C_0006: "承認メモの変更が破棄され、<br>再度入力する必要がありますが、処理を進めますか？",
  PF02F05D02_C_0007: "調整メモ、承認メモのいずれかが更新されましたが、未保存です。<br>変更を破棄して次の画面に移りますか？",
  PF02F05D02_C_0008: "承認メモが更新されましたが、未保存です。<br>変更を破棄して次の画面に移りますか？",
  PF02F05D02_C_0009: "調整値、調整メモ、承認メモのいずれかが更新されましたが、未保存です。<br>変更を破棄して次の画面に移りますか？",
  PF03F03D02_E_0001: "当該条件のデータが見つかりません",
  PF03F05D01_C_0001: "条件を複数指定する場合『",
  PF03F05D01_C_0002: "|』で区切って入力してください。",
  PF03F05D021_E_0001:"日付区分1と違うもので入力されていること",
  PF03F05D04_C_0001: "コメントを保存しますか？",
  PF03F05D04_C_0002: "注文変更の棄却を確認し、再度変更しますか？",
  PF03F05D04_C_0005: "注文変更情報を保存しますか？",
  PF03F05D04_C_0006: "注文情報を承認しますか？",
  PF03F05D04_C_0007: "注文を棄却しますか？",
  PF03F05D04_C_0008: "注文の棄却を確認しますか？",
  PF03F05D04_C_0009: "出荷情報を保存しますか？",
  PF03F05D04_C_0010: "当月請求対象区分情報を保存しますか？",
  PF03F05D04_C_0011: "詳細画面を閉じますか？",
  PF03F05D04_C_0012: "保存対象のデータがないため、保存処理を行いません。",
  PF03F05D04_C_0013: "コメント以外の更新情報を保存してください。",
  PF03F05D04_C_0014: "更新情報が保存されていません。詳細画面を閉じますか？",
  PF03F05D04_C_0015: "注文変更区域以外の更新情報を保存してください。",
  PF03F05D04_C_0016: "出荷情報が更新されましたが、反映されていません。<br>調整区域で保存してください。",
  PF03F05D04_C_0017: "当月請求対象区分情報が更新されましたが、反映されていません。<br>調整区域で保存してください。",
  PF03F05D04_C_0018: "出荷予定・実績区分を切り替える前に更新情報を保存してください。",
  PF03F05D04_E_0001: "保存対象のデータがないため、保存処理を行いません。",
  PF03F05D04_E_0002: "コメント以外の更新情報を保存してください。",
  PF03F05D04_E_0003: "注文変更区域以外の更新情報を保存してください。",
  PF03F05D04_E_0005: "出荷情報が更新されましたが、反映されていません。調整区域で保存してください。",
  PF03F05D04_E_0006: "更新情報を保存してください。",
  PF03F05D04_E_0007: "他ユーザが処理対象を更新しました。画面を最新の状態に更新の上、再入力してください。",
  PF03F05D04_E_0013: "出荷予定・実績区分を切り替える前に更新情報を保存してください。",
  PF03F05D04_I_0001: "コメントを保存しました。",
  PF03F05D04_I_0002: "注文変更の棄却を確認しました。",
  PF03F05D04_I_0005: "注文変更情報を保存しました。",
  PF03F05D04_I_0006: "注文情報を承認しました。",
  PF03F05D04_I_0007: "注文を棄却しました。",
  PF03F05D04_I_0008: "注文の棄却を確認しました。",
  PF03F05D04_I_0009: "出荷情報を保存しました。",
  PF03F05D04_I_0010: "当月請求対象区分情報を保存しました。",
  PF03F14D01_E_0001: "受注企業リストを取得できません。",
  PF03F14D01_E_0002: "加工受注企業リストを取得できません。",
  PF03F14D01_E_0003: "受注企業リストと加工受注企業リストを取得できません。",
  PF03F14D01_E_0004: "受注企業、または加工受注企業のいずれかに自社を指定してください。",
  PF03F03XXX_I_0001: "日次注文承認依頼が届きました。",
  PF03F03XXX_I_0002: "日次注文棄却確認依頼が届きました。",
  PF03F03XXX_I_0003: "アップロードエラーが発生しました。",
  PF03F03XXX_I_0004: "日次注文コメント更新が届きました。"
}

export default messages_ja;