const actions_en = {
  PF_ACTION_00001: "Login",
  PF_ACTION_00002: "Logout",
  PF_ACTION_00003: "Advanced Search",
  PF_ACTION_00004: "Download",
  PF_ACTION_00005: "Search",
  PF_ACTION_00006: "Close",
  PF_ACTION_00007: "New Entry",
  PF_ACTION_00008: "Move to Page",
  PF_ACTION_00009: "Back",
  PF_ACTION_00010: "Edit",
  PF_ACTION_00011: "Delete",
  PF_ACTION_00012: "Update",
  PF_ACTION_00013: "Confirm",
  PF_ACTION_00014: "Upload",
  PF_ACTION_00015: "Browse",
  PF_ACTION_00016: "追加",
  PF_ACTION_00017: "Approve",
  PF_ACTION_00018: "承認対象リスト表示",
  PF_ACTION_00019: "Change Password",
  PF_ACTION_00020: "Home",
  PF_ACTION_00021: "Multi-Level BOM",
  PF_ACTION_00022: "Overall Display Multi-Level BOM",
  PF_ACTION_00023: "Display Multi-Level BOM",
  PF_ACTION_00024: "Display Multi-Level BOM_Unconfirmed",
  PF_ACTION_00025: "Entry for Top Level Item",
  PF_ACTION_00026: "Entry for Component Level Item",
  PF_ACTION_00027: "Display Pending Steel R/M & F/G",
  PF_ACTION_00028: "Overall Entry for Steel R/M & F/G",
  PF_ACTION_00029: "Entry and Display Item",
  PF_ACTION_00030: "Entry for Steel R/M Group",
  PF_ACTION_00031: "連関情報登録・照会",
  PF_ACTION_00032: "Approve Multi-Level BOM",
  PF_ACTION_00033: "Approval Menu List",
  PF_ACTION_00034: "System Maintenance",
  PF_ACTION_00035: "Company Master",
  PF_ACTION_00036: "Location Master",
  PF_ACTION_00037: "User Master",
  PF_ACTION_00038: "Download Error List",
  PF_ACTION_00039: "Cancel",
  PF_ACTION_00040: "Send",
  PF_ACTION_00041: "Daily Order & Delivery Mgmt",
  PF_ACTION_00042: "Entry for Daily Steel Orders",
  PF_ACTION_00043: "Notifications",
  PF_ACTION_00044: "Delivery Mgmt Table",
  PF_ACTION_00045: "Entry for Shipment Qty.",
  PF_ACTION_00046: "Upload Batch Status",
  PF_ACTION_00047: "Download Batch Status",
  PF_ACTION_00048: "Group Master",
  PF_ACTION_00049: "User Group Master",
  PF_ACTION_00050: "Lead Time Master",
  PF_ACTION_00051: "Delivery Place Master",
  PF_ACTION_00052: "Download Delivery Mgmt Table",
  PF_ACTION_00053: "Approval List",
  PF_ACTION_00054: "OK",
  PF_ACTION_00055: "Copy",
  PF_ACTION_00056: "Register",
  PF_ACTION_00057: "Download Search",
  PF_ACTION_00058: "Confirm Rejection",
  PF_ACTION_00059: "Clear Approval List",
  PF_ACTION_00060: "",
}
  
export default actions_en;