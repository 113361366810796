import router from '@/router';
const whiteList = ['/PF01C01D02', '/','/PF01C01D05']
router.beforeEach((to, from, next) => {
  const hasToken = sessionStorage.getItem("accessToken")
  if (hasToken) {
    if (from.name) {
      next()
    } else {
      if (to.path !== '/indexPage') {
        next(`/indexPage`)
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      sessionStorage.clear()
      next(`/`)
    }
  }
});